// src/components/blocks/our-principles/ExploreCurriculum.tsx
import React from 'react';

import styled from 'styled-components';

import ForwardArrow from 'stile-shared/src/components/2024/common/Included/WITBFeatureSection/ForwardArrow';
import { Link } from 'stile-shared/src/components/2024/common/Link';

interface ExploreCardProps {
  title: string;
  description: string;
  url: string;
}

const CurriculumFooter = styled.div`
  background-color: var(--stile-grey-1);
  padding: calc(90px - (124px / 2)) 140px 100px 140px;
  width: 100%;
  font-family: 'Boing';
  font-size: 40px;
  font-weight: 500;
  line-height: 45px;
  word-wrap: break-word;

  @media (max-width: 768px) {
    padding: 30px;
    font-size: 26px;
    line-height: 28px;

    .content {
      margin: 0;
    }
  }
`;

const ExploreHeader = styled.div`
  display: flex;
  align-items: center;
  max-width: 1160px;
  justify-content: space-between;
  color: var(--stile-grey-8);
  margin-bottom: 20px;

  @media (max-width: 768px) {
    align-items: flex-start;
  }
`;

const ExploreTitle = styled.h4`
  font-size: inherit;
  line-height: inherit;
  color: var(--stile-grey-8);
  word-wrap: break;
  margin: 0;

  @media (max-width: 768px) {
    font-size: 26px;
    line-height: 28px;
    text-align: left;
    margin: 14px 0 8px 0;
    max-width: 250px;
  }
`;

const ExploreImage = styled.div`
  position: relative;
  top: 0;
  right: 0;
  height: 124px;
  width: 124px;

  @media (max-width: 768px) {
    height: 78px;
    width: 78px;
  }
`;
const FooterCardTitle = styled.div`
  font-family: Graphik;
  color: var(--stile-grey-8);
  width: 288px;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  text-align: left;
`;

const FooterArrow = styled(ForwardArrow)`
  position: absolute;
  top: 24px;
  right: 24px;
`;

const FooterCardText = styled.p`
  margin: 8px 0 0 0;
`;

const FooterCardsGroup = styled.div`
  display: flex;
  max-width: 1160px;
  gap: 28px;
  justify-content: center;
  flex-wrap: wrap;
  @media (max-width: 768px) {
    margin-bottom: 4px;
    gap: 15px;
  }
`;

const FooterCard = styled.div`
  position: relative;
  background-color: #fff;
  color: #2b2b2b;
  padding: 24px;
  min-height: 124px;
  border-radius: 16px;
  width: 368px;
  box-shadow: 0px 5px 11px rgba(0, 0, 0, 0.07), 0px 5px 11px 0px #00000012;
  font-family: Graphik;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  text-align: left;

  @media (max-width: 768px) {
    width: 100%;
  }
`;

const ExploreCurriculum: React.FC<{
  title: string;
  exploreCardsData: ExploreCardProps[];
  image: React.ReactNode; // Accept the constructed image as a prop
}> = ({ title, exploreCardsData, image }) => (
  <CurriculumFooter>
    <div className="content">
      <ExploreHeader>
        <ExploreTitle>{title}</ExploreTitle>
        <ExploreImage>{image}</ExploreImage>
      </ExploreHeader>
      <FooterCardsGroup>
        {exploreCardsData.map((card, index) => (
          <Link url={card.url} key={index} style={{ textDecoration: 'none', color: 'inherit' }}>
            <FooterCard>
              <FooterCardTitle>{card.title}</FooterCardTitle>
              <FooterArrow />
              <FooterCardText>{card.description}</FooterCardText>
            </FooterCard>
          </Link>
        ))}
      </FooterCardsGroup>
    </div>
  </CurriculumFooter>
);

export default ExploreCurriculum;
