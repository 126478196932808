import axios from 'axios';

import type { StileJobsJson } from '../../../../types/data';

const STILE_JOBS_API_URL = 'https://stileapp.com/api/au/v3/jobRoles';

export async function fetchStileJobs() {
  const { data } = await axios.get<StileJobsJson>(STILE_JOBS_API_URL);
  return data.getResponses.map((response) => response.jobRole);
}
