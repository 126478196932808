import * as React from 'react';

import { StaticImage } from 'gatsby-plugin-image';
import styled from 'styled-components';

import { Slider } from 'stile-shared/src/components/2024/common/Slider';
import { VideoPlayer } from 'stile-shared/src/components/2024/common/VideoPlayer';
import { Section } from 'stile-shared/src/components/2024/layout/Section';
import { TopSection } from 'stile-shared/src/components/2024/layout/TopSection';

import { PageLayout } from 'templates/2024/PageLayout';

import ExploreCurriculum from 'components/our-principles/ExploreCurriculum';

// Reading videos
import reading_science_articles from '../why-choose-stile/media/01-01-reading-science-articles.mp4';
import reading_annotation_exercises from '../why-choose-stile/media/01-02-reading-annotation-exercises.mp4';
import reading_glossary_tool from '../why-choose-stile/media/01-03-reading-glossary-tool.mp4';
import reading_audio_narration from '../why-choose-stile/media/01-04-reading-audio-narration.mp4';
// Writing videos
import writing_responses from '../why-choose-stile/media/02-01-writing-responses.mp4';
import writing_note_taking from '../why-choose-stile/media/02-02-writing-note-taking.mp4';
import writing_spelling_feedback from '../why-choose-stile/media/02-03-writing-spelling-feedback.mp4';
// Mathematics videos
// n.b. there is no "03-01" video for mathematics relationships, as it is an image
import math_data_analysis from '../why-choose-stile/media/03-02-math-data-analysis.mp4';
import math_equation_balancing from '../why-choose-stile/media/03-03-math-equation-balancing.mp4';

export { Head } from 'templates/2024/Head';

const RWMPageLayout = styled<typeof PageLayout>(PageLayout)`
  .header-wrapper {
    @media (max-width: 768px) {
      background-color: var(--stile-grey-1);
    }
  }
`;

const RWMPageTopSection = styled<typeof TopSection>(TopSection)`
  .grid-layout {
    padding-top: 80px !important;
    @media (max-width: 768px) {
      padding-bottom: 0px !important;
      padding-top: 17px !important;
    }

    > div {
      padding-top: 0px !important;
    }
  }

  ul {
    padding-top: 60px;
    padding-left: 25px;
  }

  .gatsby-image-wrapper {
    top: 125px !important;
    /* width: 100%; */
    /* img {
      object-fit: fill !important;
    } */
  }

  @media (max-width: 768px) {
    background: var(--stile-grey-1);
    .gatsby-image-wrapper {
      top: 00px !important;
    }
    .grid-layout {
      > div {
        padding-top: 29px !important;
      }
    }

    ul {
      padding-top: 0;
    }
  }
`;

const Quote = styled<typeof Section>(Section)`
  .content {
    padding-top: 150px !important;
    @media (max-width: 768px) {
      padding-top: 80px !important;
    }
  }

  .grid-layout {
    margin-top: 32px !important;

    @media (max-width: 768px) {
      margin-top: 25px !important;
    }

    h2 {
      margin-bottom: 140px !important;
      @media (max-width: 768px) {
        margin-bottom: 60px !important;
      }
      .quote-attribution {
        margin-top: 32px;
        font-size: 16px;
        font-family: 'Graphik';
        font-weight: 600;
        line-height: 24px;
        text-align: center;

        .quote-title {
          font-weight: 400;
        }
      }
    }
  }
`;

const RWMWrapper = styled.div`
  .content {
    padding-top: 150px !important;

    .grid-layout {
      margin-top: 25px !important;
    }
  }
  @media (max-width: 768px) {
    .content {
      padding-top: 30px !important;

      .grid-layout {
        margin-top: 20px !important;
      }
    }
  }
`;

const RWMSection = styled<typeof Section>(Section)`
  margin: 0;
  padding: 0 0 0 0;
  grid-column: span 12;
  position: relative;

  &:first-child {
    .content {
      padding-top: 150px !important;
      @media (max-width: 768px) {
        padding-top: 60px !important;
      }
    }
  }

  &:last-child {
    padding-bottom: 150px;
    @media (max-width: 768px) {
      padding-bottom: 0px;
    }
  }

  h2 {
    margin-bottom: 80px !important;
    @media (max-width: 768px) {
      margin-bottom: 40px !important;
    }
  }

  @media (max-width: 768px) {
    padding: 0;
    h2 {
      margin-bottom: 20px;
    }
    .content {
      padding: 0;
    }
  }

  .subheader {
    grid-column: 3 / span 8;
    text-align: center;
    margin: 0;

    h4 {
      margin-bottom: 32px;
    }

    p {
      margin: 0;
    }

    @media (max-width: 768px) {
      grid-column: 1 / span 12;

      p {
        &:first-child {
          margin-top: 0;
        }
        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }

  .content {
    .grid-layout {
      grid-column: span 12;
      align-items: center;
    }

    @media (max-width: 768px) {
      .grid-layout {
        margin-bottom: 60px;
      }
    }
  }

  .slider {
    grid-column: 1 / span 12;

    /* Add paragraph styling directly in slider */
    .--text p {
      margin: 0 0 12px !important; /* Set bottom margin to 12px for paragraph spacing */
    }

    .--text p:last-child {
      margin-bottom: 0; /* Remove bottom margin for the last paragraph */
    }
  }
`;

function ReadingWritingMathPage() {
  const exploreCurriculumImage = (
    <StaticImage
      src="../../components/our-principles/images/literacy_explore_curriculum.png"
      alt="Teacher Saying Quality"
      placeholder="blurred"
      layout="constrained"
      width={124}
      height={124}
    />
  );
  return (
    <RWMPageLayout>
      <RWMPageTopSection
        headingContent="Improve reading, writing and mathematics"
        subHeadingContent={
          <ul>
            <li>Stile seamlessly integrates reading, writing and math into everyday science.</li>
            <li>Skills are built explicitly and deliberately, with plenty of scaffolds.</li>
            <li>Alignment to Common Core made visible to teachers.</li>
          </ul>
        }
        coverImage={
          <StaticImage
            src="../../../../stile-shared/static/readwritemathhero.png"
            alt="Improve reading, writing and mathematics - cover image"
            placeholder="none"
            layout="constrained"
          />
        }
      />
      {/* Teacher saying quote */}
      <Quote
        backgroundColor="--white"
        subHeadingColor="--stile-green-3"
        subHeadingContent="WHAT TEACHERS ARE SAYING"
        className=""
        headingContent={
          <div>
            “The fact that there’s always a little bit of numeracy embedded in the lessons in a
            really authentic way is amazing.”
            <div className="quote-attribution">
              Michelle Fyfe
              <div className="quote-title">Science Department Chair</div>
            </div>
          </div>
        }
      >
        <></>
      </Quote>
      {/* Reading-Writing-Maths Section */}
      <RWMWrapper>
        {/* Reading */}
        <RWMSection
          backgroundColor="--stile-green-2"
          headingColor="--stile-grey-8"
          subHeadingContent="Reading"
          headingContent="Empower students at every level to excel in reading"
          textColor="--stile-grey-8"
        >
          <Slider
            secondsToFlip={20}
            tabs={['Science articles', 'Annotation', 'On-hover glossary', 'Audio narration']}
            headings={[
              'Age-appropriate science articles that spark curiosity',
              'Annotation exercises',
              'A glossary at students` fingertips',
              'Audio narration with word-by-word highlighting',
            ]}
            paragraphs={[
              'Our units feature engaging, age-appropriate science articles that are super informative. But the real kicker? They’re designed to make students lean in. Complete with comprehension questions, these articles bring concepts to life while boosting reading skills and sending curiosity off the charts.',
              'Students are guided through reading exercises in their Stile X workbooks with annotation exercises. The annotation exercises have students highlighting definitions, underlining key terms, sketching ideas and more, in a scaffolded and consistent manner.',
              'Science terms can feel like a whole new language, but with Stile’s on-hover glossary, students get quick, illustrated definitions whenever they need them. Whether in English or Spanish, and with audio read-aloud options, tricky terms are no longer stumbling blocks. Hint: they’re stepping stones.',
              'Got a tricky text? No problem. With Stile’s audio narration, students can follow along as each word is read aloud and highlighted, syncing spoken language with written text. Even better, teachers can record their own voice for a personal touch.',
            ]}
            images={[
              <VideoPlayer key={0} url={reading_science_articles} />,
              <VideoPlayer key={1} url={reading_annotation_exercises} />,
              <VideoPlayer key={2} url={reading_glossary_tool} />,
              <VideoPlayer key={3} url={reading_audio_narration} />,
            ]}
          />
        </RWMSection>

        {/* Writing */}
        <RWMSection
          backgroundColor="--stile-green-2"
          headingColor="--stile-grey-8"
          subHeadingContent="Writing"
          headingContent="Cultivate reading and writing confidence in the classroom"
          textColor="--stile-grey-8"
        >
          <Slider
            secondsToFlip={20}
            tabs={['Written responses', 'Structured note-taking', 'Spelling feedback tools']}
            headings={[
              'Clear communication through written responses',
              'Master note-taking, a skill for life',
              'Personalised feedback to sharpen spelling and grammar',
            ]}
            multiParagraphs={[
              [
                'Effective science means clear communication. Beyond multiple-choice and cloze questions, it’s vital that students express their understanding in written form. Stile integrates open-ended writing questions into most lessons, encouraging students to articulate their ideas clearly and thoughtfully.',
              ],
              [
                `Note-taking is a skill that stays with students through higher education, the workplace, and beyond. Stile helps students develop this essential skill, starting with highlighting and annotation in early grades, and progressing to Cornell note-taking techniques later on.`,
                `Got that all down?`,
              ],
              [
                'At the core of Stile’s student-teacher workflow is personalised feedback. With Stile’s feedback tools, teachers can easily highlight specific words or sentences in student responses, prompting students to continuously refine and strengthen their spelling and grammar, one response at a time.',
              ],
            ]}
            images={[
              <VideoPlayer key={0} url={writing_responses} />,
              <VideoPlayer key={1} url={writing_note_taking} />,
              <VideoPlayer key={2} url={writing_spelling_feedback} />,
            ]}
          />
        </RWMSection>

        {/* Mathematics */}
        <RWMSection
          backgroundColor="--stile-green-2"
          headingColor="--stile-grey-8"
          subHeadingContent="Mathematics"
          headingContent="Master the language of science through mathematics"
          textColor="--stile-grey-8"
        >
          <Slider
            secondsToFlip={20}
            tabs={['Mathematical relationships', 'Real-world data', 'Chemical equations']}
            headings={[
              'Unpacking mathematical relationships in science',
              'Analyse and interpret real-world data',
              'Balancing equations and boosting algebra skills',
            ]}
            paragraphs={[
              'From calculating energy efficiency to measuring population declines, Stile’s lessons help students input data into equations, making connections between numerators and denominators, ratios, and percentages. That’s the science of math for you!',
              'Stile’s lessons are packed with real-world data that students can sink their teeth into. Through analysis and interpretation, students learn to draw conclusions—or identify when conclusions can`t be drawn—honing their data literacy and critical thinking skills.',
              'Students explore the mathematical foundations of chemical equations in Stile’s lessons. As they practise balancing these equations, they reinforce their understanding of algebra, seamlessly blending science and math to solidify core concepts. Solve x, have fun, repeat!',
            ]}
            images={[
              <StaticImage
                key={0}
                src="./media/03-01-math-relationships.png"
                alt="Unpacking mathematical relationships in science"
                placeholder="blurred"
                quality={100}
                layout="constrained"
              />,
              <VideoPlayer key={1} url={math_data_analysis} />,
              <VideoPlayer key={2} url={math_equation_balancing} />,
            ]}
          />
        </RWMSection>
      </RWMWrapper>

      <ExploreCurriculum
        title="Explore our core science curriculum"
        image={exploreCurriculumImage}
        exploreCardsData={[
          {
            title: 'Technology',
            description:
              'From customising lessons to tracking student performance, you`re always in control.',
            url: '/what-is-stile/technology-for-teaching/',
          },
          {
            title: 'Inside a Stile Classroom',
            description: 'Collaborative, interactive learning that goes beyond the curriculum.',
            url: '/what-is-stile/stile-classroom',
          },
          {
            title: 'Why HQIM?',
            description: 'Why High-Quality Instructional Materials make all the difference.',
            url: 'https://blog.stileeducation.com/3-reasons-to-adopt-hqim/',
          },
        ]}
      />
    </RWMPageLayout>
  );
}

export default ReadingWritingMathPage;
