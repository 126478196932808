import styled from 'styled-components';

export const StyledWITBStatItem = styled.div`
  text-align: center;
  flex: 1;
  /* min-width: 290px; */
  max-height: 400px;

  h2 {
    color: var(--stile-green-2);
    margin: 40px 0 0 0;
  }

  h6 {
    color: var(--white);
    margin: 0 0 40px 0;
  }

  @media (max-width: 768px) {
    min-width: unset;
    h2 {
      margin: 20px 0 0px 0;
    }
    h6 {
      margin: 0 0 4px 0;
    }
`;
