import underline13_mobile from 'stile-shared/static/underline-13-mobile.svg';
import underline13 from 'stile-shared/static/underline-13.svg';
import styled from 'styled-components';

export const StyledWITBUnderlinedText = styled.span`
  position: relative;
  display: inline-block;

  &:after {
    content: '';
    position: absolute;
    left: 0;
    bottom: -7px;
    width: 95px;
    height: 24px;
    background-image: url(${underline13});
    background-repeat: no-repeat;
    background-size: cover;
    z-index: 500;
    /* transform: rotate(-4.02deg);  */
  }

  @media (max-width: 768px) {
    scale: 100%;
    &:after {
      background-image: url(${underline13_mobile});
      width: 80px;
      height: 20px;
      bottom: -3px;
    }
  }
`;
