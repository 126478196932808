export function getJobIdFromPath(path: string) {
  const pathMatchArray = path.match(
    /apply\/([a-f0-9]{8}-[a-f0-9]{4}-[a-f0-9]{4}-[a-f0-9]{4}-[a-f0-9]{12})/i
  );
  if (pathMatchArray) {
    return pathMatchArray[1];
  }

  return undefined;
}
