import axios from 'axios';

import { JobResponse } from '../../../../types/data';

const STILE_JOB_BY_ID_API_URL = `https://stileapp.com/api/au/v3/jobRoles/`;

export async function fetchStileJobById(jobId: string) {
  const { data } = await axios.get<JobResponse>(`${STILE_JOB_BY_ID_API_URL}${jobId}`);
  return data.jobRole;
}
