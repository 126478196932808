import styled, { css } from 'styled-components';

const generateWidthStyles = () => {
  let styles = '';
  for (let i = 0; i <= 100; i += 1) {
    styles += `
      &.--active-${i}::after {
        width: ${i}%;
      }
    `;
  }
  return css`
    ${styles}
  `;
};

export const StyledSlider = styled.div<{ $animationLength?: string }>`
    display: flex;
    padding: 30px 60px 60px 60px;
    z-index: 2;
    flex-direction: column;
    align-items: flex-start;
    /* gap: 10px; */
    flex-shrink: 0;
    border-radius: 32px;
    background-color: var(--white);
    color: var(--stile-grey-8);
    box-shadow: 0px 660px 185px 0px rgba(0, 0, 0, 0), 0px 422px 169px 0px rgba(0, 0, 0, 0.01),
      0px 238px 143px 0px rgba(0, 0, 0, 0.04), 0px 106px 106px 0px rgba(0, 0, 0, 0.07),
      0px 26px 58px 0px rgba(0, 0, 0, 0.08);

    @media (max-width: 768px) {
      border-radius: 20px;
      padding: 25px 25px 10px 25px;
      gap: 0;
    }

    .--header {
      width: calc(100% - 24px);
      padding: 0;
      margin: 0;
      display: flex;
      column-gap: 24px;
      cursor: pointer;

      .--headerButton {
        flex: 1 0 24%;
      }

      @media (max-width: 768px) {
        display: none;
      }
    }

    .--headerButton {
      padding-top: 17px;
      background-color: transparent;
      border: none;
      /* border-left: none;
      border-bottom: none;
      border-right: none;
      border-top-width: 3px;
      border-top-style: solid;
      border-top-color: var(--stile-grey-3); */
      text-decoration: none;
      text-align: left;
      cursor: pointer;
      color: var(--stile-grey-6);
      @media (max-width: 768px) {
        color: var(--stile-grey-7);
      }
      position: relative;
      display: flex;
      gap: 0px;
      &:before {
        content: '';
        position: absolute;
        left: 0;
        top: 2px;
        display: block;
        width: 100%;
        height: 3px;
        border-radius: 2px;
        background-color: var(--stile-grey-3);
      }
      &.--active {
        color: var(--stile-green-3);
        &:after {
          content: '';
          position: absolute;
          left: 0;
          top: 0px;
          display: block;
          width: 0%;
          height: 7px;
          border-radius:3px;
          background-color: var(--stile-green-2);
          transition-property: width;
          transition-timing-function: linear;
          transition-duration: ${(props) => props.$animationLength || '1s'};
        }

        ${generateWidthStyles()}

        @media (max-width: 768px) {
          /* padding-top: 16px; */
        }
      }

      @media (max-width: 768px) {
        width: 100%;
        padding: 16px 0 16px 0;
        /* border-top-width: 5px; */
      }

      h6 {
        padding: 0px 0 0 0;
        margin: 0;
      }
    }

    .--content-container {
      width: 100%;
      height: 0;
      overflow: hidden;
      &:has(.--content.--active) {
        height: auto;
      }

      @media (max-width: 768px) {
        height: auto;
        .--content {
          overflow: hidden;
        }
      }

      .--headerButton {
        display: none;
        svg {
          display: inline-flex;
        }
        @media (max-width: 768px) {
          width: 100%;
          display: flex;
          justify-content: space-between;
          align-items: flex-end;
          justify-items: center;
        }
      }
      .--content {
        padding-top: 80px;
        justify-content: space-between;
        /* display: none; */
        opacity: 0;
        height: 0px;
        transition: opacity 1s;
        z-index: 1;
        display: flex;
        position: relative;

        @media (max-width: 768px) {
          padding-top: 0px;
        }
        &.--active {
          /* display: flex; */
          height: auto;
          opacity: 1;
          @media (max-width: 768px) {
            flex-direction: column-reverse;
          }
        }
        .--text {
          width: 45%;
          display: flex;
          flex-direction: column;
          justify-content: end;
          padding-bottom: 80px;
          h4 {
            margin: 0 0 28px 0;
            padding: 0;
            @media (max-width: 768px) {
              margin: 0 0 12px 0;
            }
          }

          p {
            margin: 28px 0;
            padding: 0;
            @media (max-width: 768px) {
              margin: 0;
            }

            &.whatYoullLearn {
              font-weight: 600;
              @media (max-width: 768px) {
                padding: 20px 0;
              }
            }
          }

          ul {
            margin: 0;
            list-style: disc;
            @media (max-width: 768px) {
              margin-bottom: 20px;
            }
            li {
              padding-bottom: 10px;
            }
          }

          @media (max-width: 768px) {
            width: 100%;
            padding-bottom: 22px;
          }
        }
      }

      .--image {
        width: 45%;

        @media (max-width: 768px) {
          width: 100%;
          padding-bottom: 20px;
        }
      }
    }
  }
}`;
