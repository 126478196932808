import * as React from 'react';

import type { HeadProps, PageProps } from 'gatsby';
import { StaticImage } from 'gatsby-plugin-image';

import { CtaBlock } from 'stile-shared/src/components/2020/blocks/CtaBlock';
import { P } from 'stile-shared/src/components/2020/common/Primitives';

import { PageLayout } from 'templates/2020/PageLayout';
import { SEO } from 'templates/2020/SEO';

import { ResourceAccessBlock } from 'components/common/ResourceAccessBlock';

export function Head(props: HeadProps) {
  return (
    <SEO
      title="NGSS High School"
      description="Teacher planning resource"
      slug={props.location.pathname}
    />
  );
}

function NGSSHighSchoolPage(props: PageProps) {
  return (
    <PageLayout {...props}>
      <ResourceAccessBlock
        title="NGSS High School"
        subtitle="Teacher planning resource"
        id="NGSS_HIGH_SCHOOL"
        redirectUrl="http://stileapp.com/go/ngss_hs_scope_sequence"
        image={
          <StaticImage
            src="../../../../stile-shared/assets/images/resources/ngss_high_school.png"
            alt=""
          />
        }
      >
        <P>
          Stile currently only provides full NGSS alignment for grades 6-8, but we offer
          supplementary content in our library. Interested in seeing how Stile can be used at the
          high school level? We'd love to show you how we can help! Fill out the form to get
          started.
        </P>
      </ResourceAccessBlock>

      <CtaBlock />
    </PageLayout>
  );
}

export default NGSSHighSchoolPage;
