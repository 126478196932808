import React from 'react';

import FilePlayer from 'react-player/file';

interface VideoPlayerProps {
  url: string;
  playing?: boolean;
  loop?: boolean;
  muted?: boolean;
  controls?: boolean;
  width?: string;
  height?: string;
  borderRadius?: string;
}

export const VideoPlayer: React.FC<VideoPlayerProps> = ({
  url,
  playing = true,
  loop = true,
  muted = true,
  controls = false,
  width = '100%',
  height = '100%',
  borderRadius = '16px',
}) => {
  return (
    <div
      className="video"
      style={{
        borderRadius, // Corrected this line
        overflow: 'hidden',
      }}
    >
      <FilePlayer
        url={url}
        playing={playing}
        loop={loop}
        muted={muted}
        controls={controls}
        width={width}
        height={height}
      />
    </div>
  );
};
