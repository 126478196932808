import * as React from 'react';

import type { FilteredListing } from '../../../../utils/getFilteredListings';
import { withListItemRole } from '../../../../utils/withListItemRole';

import { theme } from '../../../../styles/2020/theme';

import { H2, H3, P, Section, Text } from '../../common/Primitives';
import { Box } from '../../layout/Box';
import { Center } from '../../layout/Center';
import { Clamp } from '../../layout/Clamp';
import { Column } from '../../layout/Column';
import { Sidebar } from '../../layout/Sidebar';

type FilteredListingsProps<Listings = FilteredListing[]> = React.ComponentProps<typeof Sidebar> & {
  title: React.ReactNode;
  deepLink?: string;
  errorMessage: React.ReactNode;
  filters: React.ReactNode;
  listings: Listings;
  ListingComponent: (props: any) => JSX.Element;
  isError: boolean;
  isLoading: boolean;
  fullWidth?: boolean;
  knownJobIds?: Array<string>;
  sidebarText?: string | React.ReactNode;
};

export function FilteredListings<Listings = FilteredListing[]>({
  title,
  deepLink,
  filters,
  listings,
  errorMessage,
  ListingComponent,
  isError,
  isLoading,
  fullWidth = false,
  knownJobIds,
  sidebarText,
  ...props
}: FilteredListingsProps<Listings>) {
  return (
    <Section>
      <Center max={fullWidth ? theme.space.siteWidth : theme.space.measure} intrinsic={fullWidth}>
        <Sidebar sideWidth="270px" contentMin="300px" {...props}>
          <Column>
            {typeof title === 'string' ? <H2 id={deepLink}>{title}</H2> : title}
            {!isError && filters}
            {sidebarText && <P>{sidebarText}</P>}
          </Column>
          <Clamp>
            <Box space={[theme.space.xxxs, 0]}>
              {isLoading ? (
                <H3>Loading...</H3>
              ) : (
                <Listings errorMessage={errorMessage}>
                  {Array.isArray(listings) &&
                    listings.map(
                      (listing: FilteredListing) =>
                        listing.active === true &&
                        listing.count > 0 && (
                          <Listing key={listing.slug} title={listing.slug}>
                            {listing.items.map((item, i) => (
                              <ListingComponent key={i} knownJobIds={knownJobIds} {...item} />
                            ))}
                          </Listing>
                        )
                    )}
                </Listings>
              )}
            </Box>
          </Clamp>
        </Sidebar>
      </Center>
    </Section>
  );
}

type ListingProps = { title: string };

const Listing = ({ title, children }: React.PropsWithChildren<ListingProps>) => {
  const count = React.Children.count(children);

  return (
    <Column space={theme.space.s}>
      <Text as="h3" bold>{`${title} (${count})`}</Text>
      <Column role="list">{React.Children.map(children, withListItemRole)}</Column>
    </Column>
  );
};

type ListingsProps = React.ComponentProps<typeof Column> & {
  errorMessage?: React.ReactNode;
};

const Listings = ({ children, errorMessage }: React.PropsWithChildren<ListingsProps>) => {
  const hasItems = React.Children.toArray(children).filter(Boolean).length;

  return (
    <Column space={theme.space.m}>
      {hasItems ? (
        children
      ) : (
        <>
          <Text as="h3" bold>
            Unavailable
          </Text>
          {errorMessage}
        </>
      )}
    </Column>
  );
};
