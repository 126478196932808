import * as React from 'react';

import { StaticImage } from 'gatsby-plugin-image';
import styled from 'styled-components';

import { TriSlider } from 'stile-shared/src/components/2024/common/TriSlider';
import { Section } from 'stile-shared/src/components/2024/layout/Section';
import { TestimonialsSection } from 'stile-shared/src/components/2024/layout/TestimonialsSection';
import { TopSection } from 'stile-shared/src/components/2024/layout/TopSection';

import { PageLayout } from 'templates/2024/PageLayout';

import ExploreCurriculum from 'components/our-principles/ExploreCurriculum';

// Video and GIF imports
import editanything from './media/01-01-prepare-edit-anything.mp4';
import bringfavourites from './media/01-02-prepare-bring-favourites.mp4';
import differentiate from './media/01-03-prepare-differentiate.mp4';
import simulatetools from './media/02-01-teach-simulate-tools.gif';
import feedbackgif from './media/02-02-teach-feedback.gif';
import screencontrol from './media/02-03-teach-control-screen.gif';
import reviewwork from './media/03-01-analyse-review-work.gif';
import classwide from './media/03-02-analysis-class-wide.gif';
import speedgrade from './media/03-03-analysis-speedgrade-ai.gif';

export { Head } from 'templates/2024/Head';

const TechPageLayout = styled<typeof PageLayout>(PageLayout)`
  .header-wrapper {
    @media (max-width: 768px) {
      background-color: var(--stile-grey-1);
    }
  }
`;

const TechPageTopSection = styled<typeof TopSection>(TopSection)`
  .grid-layout {
    padding-bottom: 400px !important;
    @media (max-width: 768px) {
      padding-bottom: 0px !important;
    }
  }

  ul {
    padding-top: 36px;
    padding-left: 25px;
  }

  .gatsby-image-wrapper {
    top: 125px !important;
    width: 100%;
    img {
      object-fit: contain !important;
    }
    /* padding-top: 50px !important; */
  }

  @media (max-width: 768px) {
    background: var(--stile-grey-1);
    .gatsby-image-wrapper {
      top: 00px !important;
    }
    .grid-layout {
      > div {
        padding-top: 29px !important;
      }
    }

    ul {
      padding-top: 0;
    }
  }
`;

const Quote = styled<typeof Section>(Section)`
  .grid-layout {
    margin-top: 50px !important;

    @media (max-width: 768px) {
      margin-top: 0px !important;
    }

    h2 {
      margin-bottom: 140px !important;
      @media (max-width: 768px) {
        margin-bottom: 60px !important;
      }
      .quote-attribution {
        margin-top: 32px;
        font-size: 16px;
        font-family: 'Graphik';
        font-weight: 600;
        line-height: 24px;
        text-align: center;

        .quote-title {
          font-weight: 400;
        }
      }
    }
  }
`;

const PTAWrapper = styled.div`
  .content {
    padding-top: 150px !important;

    .grid-layout {
      margin-top: 25px !important;
    }
  }
  @media (max-width: 768px) {
    .content {
      padding-top: 60px !important;

      .grid-layout {
        margin-top: 20px !important;
      }
    }
  }
`;

const PTAAcademySection = styled<typeof Section>(Section)`
  margin: 0;
  padding: 0 0 0 0;
  grid-column: span 12;
  position: relative;

  :last-child {
    padding-bottom: 150px;
    @media (max-width: 768px) {
      padding-bottom: 30px;
    }
  }

  h2 {
    margin-bottom: 80px !important;
    @media (max-width: 768px) {
      margin-bottom: 40px !important;
    }
  }

  @media (max-width: 768px) {
    padding: 0;
    h2 {
      margin-bottom: 20px;
    }
    .content {
      padding: 0;
    }
  }

  .subheader {
    grid-column: 3 / span 8;
    text-align: center;
    margin: 0;

    h4 {
      margin-bottom: 32px;
    }

    p {
      margin: 0;
    }

    @media (max-width: 768px) {
      grid-column: 1 / span 12;

      p {
        &:first-child {
          margin-top: 0;
        }
        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }

  .content {
    .grid-layout {
      grid-column: span 12;
      align-items: center;
    }

    @media (max-width: 768px) {
      padding-top: 60px !important;
      .grid-layout {
        margin-bottom: 30px !important;
      }
    }
  }

  .slider {
    grid-column: 1 / span 12;
  }
`;

const testimonials = [
  {
    name: 'Elizabeth Neher',
    title: 'Lake Oswego',
    quote:
      'My favorite thing about Stile is that every feature in it is 100% customizable and editable by the teacher. So I can take the great platform that’s already there, the base that they put together and make it work for the needs of my individual students.',
  },
  {
    name: 'Teacher',
    title: 'Los Alisos Middle School',
    quote:
      'The Stile platform provided in-depth, real-time interactions between us teachers and the students.',
  },
  {
    name: 'Billi Jones',
    title: 'American International School of Bucharest',
    quote:
      "Stile is the best platform I've ever used -- and that's over 20 plus years of teaching.",
  },
  {
    name: 'Olivia Bailey',
    title: 'Astoria Middle School',
    quote:
      'We like how customizable it is as well as the immediate feedback and the formative assessments. It’s just really user friendly for teachers and it saves us a ton of time.',
  },
];

function TechnologyForTeachingPage() {
  const exploreCurriculumImage = (
    <StaticImage
      src="../../components/our-principles/images/technology_explore_curriculum.png"
      alt="Teacher Saying Quality"
      placeholder="blurred"
      layout="constrained"
      width={124}
      height={124}
    />
  );

  return (
    <TechPageLayout>
      <TechPageTopSection
        headingContent="Technology that supports teachers"
        subHeadingContent={
          <ul>
            <li>Award-winning technology for vibrant, dynamic classrooms.</li>
            <li>Customize lessons, track progress, stay in control.</li>
            <li>Empower teachers, boost learning outcomes.</li>
          </ul>
        }
        coverImage={
          <StaticImage
            src="../../../../stile-shared/assets/images/technology-for-teaching/cover_tech_for_teaching_2024.png"
            alt="Technology for Teachers - cover image"
            placeholder="none"
            layout="fullWidth"
          />
        }
      />
      {/* Stile founder quote */}
      <Quote
        backgroundColor="--white"
        subHeadingColor="--stile-green-2"
        subHeadingContent=""
        className=""
        headingContent={
          <div>
            “Stile classrooms are beautifully blended – the perfect mix of print, practical and
            digital.”
            <div className="quote-attribution">
              Danny Pikler
              <div className="quote-title">Stile Co-founder</div>
            </div>
          </div>
        }
      >
        <></>
      </Quote>

      {/* Prepare-Teach-Analyse Section */}
      <PTAWrapper>
        <PTAAcademySection
          backgroundColor="--stile-green-2"
          headingColor="--stile-grey-8"
          headingContent="Customize any lesson with simple point-and-click tools"
          subHeadingContent="Prepare"
          textColor="--stile-grey-8"
        >
          {/* Prepare */}
          <TriSlider
            secondsToFlip={20}
            tabs={[
              'Edit your lesson',
              'Bring in your favourites',
              'Differentiate lessons for all abilities',
            ]}
            headings={[
              'Edit. Literally. Anything.',
              'Bring in your favourites',
              'Differentiate lessons for all abilities',
            ]}
            description={[
              'Rearrange, remove, or tweak any part of your lesson—whether it’s questions, content, or activities. The flexibility is all yours.',
              'No rigid scripts here. Add the videos, questions, and activities you love, knowing your students will too.',
              'Group students by ability and give them lessons that not only inspire and delight but match their skill levels too.',
            ]}
            videos={[editanything, bringfavourites, differentiate]}
            paragraphs={[]}
          />
        </PTAAcademySection>

        {/* Teach */}
        <PTAAcademySection
          backgroundColor="--stile-green-2"
          headingColor="--stile-grey-8"
          headingContent="Technology so smooth, it feels like magic"
          subHeadingContent="Teach"
          textColor="--stile-grey-8"
        >
          <TriSlider
            secondsToFlip={20}
            tabs={['Simulate discussion', 'Timely feedback', 'Student screen control']}
            headings={[
              'Tools to stimulate discussion and collaboration',
              'Provide students with timely feedback',
              'Control student screens with a click of a button',
            ]}
            description={[
              'Sometimes, the magic of technology can help even the quietest students find their voice and stimulate rich discussion.',
              'Feedback is one of the most powerful tools for boosting student outcomes. It’s especially impactful when timely.',
              'Get students back on track and focused on you with just one click, using the Teleport and Pause buttons.',
            ]}
            gifs={[simulatetools, feedbackgif, screencontrol]}
            paragraphs={[]}
          />
        </PTAAcademySection>

        {/* Analyse */}
        <PTAAcademySection
          backgroundColor="--stile-green-2"
          headingColor="--stile-grey-8"
          headingContent="Use data-informed insights to shape your next lesson"
          subHeadingContent="Analyse"
          textColor="--stile-grey-8"
        >
          <TriSlider
            secondsToFlip={20}
            tabs={['Speed grading with AI', 'Review work effectively', 'Class-wide analytics']}
            headings={[
              'Speed grading featuring AI',
              'Review individual student work',
              'See class-wide analytics for each concept',
            ]}
            description={[
              'Need support with feedback? Stile Intelligence uses the power of AI to suggest a first draft, considering everything from the lesson to the model answer.',
              'Use Stile’s Gradebook to dive deep into each student’s response to every question.',
              'Small tweak: Use Stile’s Gradebook to dive deep into each student’s response to every question.',
            ]}
            gifs={[speedgrade, reviewwork, classwide]}
            paragraphs={[]}
          />
        </PTAAcademySection>
      </PTAWrapper>

      <TestimonialsSection testimonials={testimonials} headingText="Our teachers have spoken" />

      <ExploreCurriculum
        title="Explore our core science curriculum"
        image={exploreCurriculumImage}
        exploreCardsData={[
          {
            title: 'Reading, Writing & Mathematics',
            description: 'Transforms every lesson into a multidisciplinary powerhouse.',
            url: '/why-choose-stile/boost-reading-writing-and-math/',
          },
          {
            title: 'Inside a Stile Classroom',
            description: 'Helping teachers implement evidence-based strategies.',
            url: '/what-is-stile/stile-classroom',
          },
          {
            title: 'Why HQIM?',
            description: 'Why High-Quality Instructional Materials make all the difference.',
            url: 'https://blog.stileeducation.com/3-reasons-to-adopt-hqim/',
          },
        ]}
      />
    </TechPageLayout>
  );
}

export default TechnologyForTeachingPage;
