import React from 'react';

import { StyledWITBTopSection } from './WITBTopSection.styles';

interface WITBTopSectionProps {
  title: string;
  description: string;
}

const WITBTopSection: React.FC<WITBTopSectionProps> = ({ title, description }) => (
  <StyledWITBTopSection>
    <h1>{title}</h1>
    <p>{description}</p>
  </StyledWITBTopSection>
);

export default WITBTopSection;
