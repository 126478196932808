export const team = [
  // Leadership

  {
    image: 'staffportrait_daniel_pikler.jpg',
    name: 'Daniel Pikler',
    role: 'CEO & Co-founder',
    background:
      'After working as a musician, then as a geneticist at the CSIRO in Canberra, Danny studied secondary teaching in Melbourne and saw first-hand the need for better science teaching resources. Danny joined Byron and Alan to found Stile in 2012.',
    categories: ['leadership'],
  },
  {
    image: 'staffportrait_jayde_taylor.png',
    name: 'Jayde Taylor',
    role: 'Chief Financial Officer',
    background:
      'Jayde was traveling in South America when she started to think differently about purpose. She had been working in New York and London for award-winning creative agencies, integrating new digital ventures for a rapidly changing market. In Stile she saw a unique opportunity to unlock the potential of young people with innovative content and technology.',
    categories: ['leadership'],
  },
  {
    image: 'staffportrait_byron_scaf.png',
    name: 'Byron Scaf',
    role: 'CEO & Co-founder',
    background: `Byron got a few months into a PhD in Neuroscience at Melbourne University before building Australia’s first electric vehicle charging network with Better Place. Finally understanding what STEM meant in the real world, he co-founded Stile in 2012 and set about working with schools.`,
    categories: ['leadership'],
  },
  {
    image: 'staffportrait_alan_finkel.png',
    name: 'Alan Finkel',
    role: 'Chair & Co-founder',
    background: `Alan Finkel is a neuroscientist, engineer and entrepreneur. He was Australia’s chief scientist from 2016 to 2020, during which time he led the National Electricity Market Review, the development of the National Hydrogen Strategy, and the panel that advised the Australian Government on the 2020 Low Emissions Technology Roadmap. Amongst Alan’s other roles within the government, Alan has returned as our Chair. `,
    categories: ['leadership'],
  },
  {
    image: 'staffportrait_adrian_lowe.jpg',
    name: 'Adrian Lowe',
    role: 'Head of Schools Australia',
    background: '',
    categories: ['community', 'teaching-learning'],
  },
  {
    image: 'staffportrait_kat_gentry.png',
    name: 'Kat Gentry',
    role: 'Head of Stile USA',
    background:
      'Kat was a science teacher before researching pest management in aquaculture across Norway, Australia, and New Zealand. Striving to cultivate student curiosity, enhance teacher job satisfaction, and bring more science classrooms to life, Kat leads Stile’s US team.',
    categories: ['leadership', 'content', 'team-usa'],
  },
  {
    image: 'staffportrait_daniel_rodgers-pryor.png',
    name: 'Daniel Rodgers-Pryor',
    role: 'Chief Technology & Security Officer',
    background: `Daniel’s academic background in Condensed Matter Physics and Computer Science gave him a passion for promoting scientific literacy and the skills to manage complex computing systems. Daniel joined Stile in 2014 and stepped into the role of CTO in 2017.`,
    categories: ['leadership'],
  },
  {
    image: 'staffportrait_clare_feeney.png',
    name: 'Clare Feeney',
    role: 'Chief Schools Officer',
    background:
      'Clare started as a medical scientist before she was introduced to the joy of teaching when tutoring refugee families. As a science and maths teacher, she became aware of the positive impact of technology. In 2015, she joined Education Perfect and built their Science, Mathematics, Geography, History and Numeracy resources. In 2019, she joined Stile and is responsible for developing all teaching and learning resources.',
    categories: ['leadership'],
  },
  {
    image: 'staffportrait_jaclyn_rooney.png',
    name: `Jaclyn Rooney`,
    role: 'Head of Teacher Engagement and Professional Learning ',
    background:
      'Jaclyn is a dedicated educator, bringing a wealth of experience in teaching, curriculum design, and teacher professional learning in both science and physical education. She joined Stile in 2016 and is responsible for Professional Learning. She is passionate about supporting, celebrating and enhancing the amazing work that science teachers do to help make science learning not only impactful, but relevant and engaging for students.',
    categories: ['product', 'leadership'],
  },

  // Content
  {
    image: 'staffportrait_campbell_edgar.png',
    name: 'Campbell Edgar',
    role: 'Science Curriculum Editor',
    background: '',
    categories: ['content'],
  },
  {
    image: 'staffportrait_toni_cox.png',
    name: 'Toni Cox',
    role: 'Head of Special Projects',
    background: '',
    categories: ['content'],
  },
  {
    image: 'staffportrait_hamish_mccoy.png',
    name: 'Hamish McCoy',
    role: 'Science Curriculum Editor',
    background: '',
    categories: ['content'],
  },
  {
    image: 'staffportrait_marcus_finlay.png',
    name: 'Marcus Finlay',
    role: 'Science Curriculum Writer',
    background: '',
    categories: ['content'],
  },
  {
    image: 'staffportrait_andy_smith.png',
    name: 'Andy Smith',
    role: 'Science Team Leader',
    background: '',
    categories: ['content'],
  },
  {
    image: 'staffportrait_mark_picardo.png',
    name: 'Mark Picardo',
    role: 'North Carolina Science Education Leader',
    background:
      "Mark is North Carolina's science education leader with experience as a teacher in elementary and special education settings and as a building administrator. Dedicated to empowering students with diverse needs, Mark brings expertise in differentiation and curriculum design to his work. He joined Stile Education to empower teachers with hands-on, standards-aligned tools that elevate science instruction and inspire students to think like scientists and engineers.",
    categories: ['team-usa'],
  },
  {
    image: 'staffportrait_mark_mannion.png',
    name: 'Mark Mannion',
    role: 'Science Content Coordinator',
    background: '',
    categories: ['content'],
  },
  {
    image: 'staffportrait_caitlyn_jones.png',
    name: 'Caitlyn Jones',
    role: 'Executive Assistant',
    background: '',
    categories: ['content'],
  },
  {
    image: 'staffportrait_charlotte_newall2.png',
    name: 'Charlotte Newall',
    role: 'US Product Operations and EA',
    background: '',
    categories: ['content'],
  },
  {
    image: 'staffportrait_tim_brade.png',
    name: 'Tim Brade',
    role: 'Studio Manager',
    background: '',
    categories: ['content'],
  },
  {
    image: 'staffportrait_bee_spencer.png',
    name: 'Bee Spencer',
    role: 'Science Content Coordinator',
    background: '',
    categories: ['content'],
  },
  {
    image: 'staffportrait_dimity_hately.png',
    name: 'Dimity Hately',
    role: 'Special Projects Coordinator',
    background: '',
    categories: ['content'],
  },
  {
    image: 'staffportrait_penelope_creagh.png',
    name: 'Penelope Creagh',
    role: 'Science Curriculum Writer',
    background: '',
    categories: ['content'],
  },

  // Community
  {
    image: 'staffportrait_hailey_vogel.png',
    name: 'Hailey Vogel Carvajal',
    role: 'Teaching and Learning Specialist',
    background:
      "Hailey is a former special education science teacher who began her career in Brooklyn, NY, before transitioning to a research coordinator role with NYC's Education Department. At Stile, she provides teachers with training and on-the ground support.",
    categories: ['community', 'teaching-learning', 'team-usa'],
  },
  {
    image: 'staffportrait_chloe_marie.png',
    name: 'Chloe Marie',
    role: 'Professional Learning Leader ',
    background: '',
    categories: ['community', 'teaching-learning'],
  },
  {
    image: 'staffportrait_elise_kovacs.png',
    name: 'Elise Kovacs',
    role: 'Head of Schools, NSW',
    background: '',
    categories: ['community', 'teaching-learning'],
  },
  {
    image: 'staffportrait_meghan_fennessy.png',
    name: 'Meghan Fennessy',
    role: 'Operations Coordinator',
    background: 'I must confess, I am actually an English teacher.',
    categories: ['community', 'teaching-learning'],
  },
  {
    image: 'staffportrait_dave_canavan.png',
    name: 'Dave Canavan',
    role: 'Head of Schools, QLD',
    background: 'Snake catching, science teaching inventor.',
    categories: ['community', 'teaching-learning'],
  },
  {
    image: 'staffportrait_steve_dornan.png',
    name: 'Steve Dornan',
    role: 'Head of Schools, WA',
    background: '',
    categories: ['community', 'teaching-learning'],
  },
  {
    image: 'staffportrait_jules_ius.png',
    name: 'Jules Ius',
    role: 'Senior Teacher Happiness Officer',
    background: 'I dish out happiness.',
    categories: ['community', 'happiness'],
  },
  {
    image: 'staffportrait_leah_jabornik.png',
    name: 'Leah Jabornik',
    role: 'Teacher Happiness Team Lead',
    background: 'I dish out happiness.',
    categories: ['community', 'happiness'],
  },
  {
    image: 'staffportrait_paul_giardina.png',
    name: 'Paul Giardina',
    role: 'Senior Teacher Happiness Officer',
    background: 'I dish out happiness.',
    categories: ['community', 'happiness'],
  },
  {
    image: 'staffportrait_kelly_fernandez.png',
    name: 'Kelly Fernandez',
    role: 'Sales Operations Analyst',
    background: '',
    categories: ['community'],
  },
  {
    image: 'staffportrait_katrina_don_paul.png',
    name: 'Katrina Don Paul',
    role: 'Customer Operations Officer',
    background: 'Teacher, Scientist, Mum, Netflix (not necessarily in that order...)',
    categories: ['community', 'teaching-learning', 'happiness'],
  },
  {
    image: 'staffportrait_julianna_jimenez.png',
    name: 'Julianna Jimenez',
    role: 'Director of School and District Partnerships',
    background:
      'Julianna failed her HS science classes which led her to believe she was terrible at science. That changed during her 3rd year of community college where a science teacher inspired her new path of becoming a HS science teacher. She is passionate about making health and science education fun, relevant, and accessible to all.',
    categories: ['community', 'teaching-learning', 'team-usa'],
  },
  {
    image: 'staffportrait_shawna_jensen.png',
    name: 'Shawna Jensen',
    role: 'Head of Curriculum and Pedagogy',
    background:
      'Shawna, a former secondary teacher and instructional coach with a focus on equity & literacy, has vast teaching experience and a doctorate in educational technology. Passionate about evidence-based practices, she believes in technology’s power to boost engagement. With over ten years in the K12 field, she champions equitable learning for all.',
    categories: ['community', 'teaching-learning'],
  },
  {
    image: 'staffportrait_mel_horton.png',
    name: 'Mel Horton',
    role: 'Oregon Science Education Leader',
    background:
      'Mel, an experienced teacher with over 11 years in the classroom, is driven by the goal of eliminating the question "Why do we have to learn this?" from her students. An Oregon native, she empathizes with the challenges faced by teachers in engaging students. Mel is thrilled to take on her new position as the Oregon Science Education lead with Stile, where she will support teachers in revitalizing the joy of teaching.',
    categories: ['community', 'team-usa'],
  },
  {
    image: 'staffportrait_stiele_hagens.png',
    name: 'Stiele Hagens',
    role: 'Operations Manager',
    background: '',
    categories: ['community'],
  },

  // Engineering
  {
    image: 'staffportrait_rhys_walsh_tindall.png',
    name: 'Rhys Walsh-Tindall',
    role: 'Support Engineer',
    background: '',
    categories: ['engineering'],
  },
  {
    image: 'staffportrait_alex_finkel.png',
    name: 'Alex Finkel',
    role: 'Head of Platform Engineering',
    background: `Fantasy/sci-fi nerd, ballroom/latin dancer, sometimes abuses apostrophe’s.`,
    categories: ['engineering', 'content', 'product'],
  },
  {
    image: 'staffportrait_matt_hanlon.png',
    name: 'Matt Hanlon',
    role: 'Marketing Champion',
    background: `Matt is a former journalist, photo and video nerd, and marketing jack-of-all-trades. His science career may have peaked in fifth grade when he won his school science fair but he's remained as curious as ever. He loves being outdoors, making and eating good food, and reading about weather phenomena. Matt joined Stile Education because he believes in developing scientifically literate youth today to build a better future tomorrow.`,
    categories: ['marketing', 'team-usa'],
  },
  {
    image: 'staffportrait_matt_needham.png',
    name: 'Matthew Needham',
    role: 'Head of Business Systems',
    background: `“When one Matt just isn’t enough.”`,
    categories: ['engineering'],
  },
  {
    image: 'staffportrait_quynh-chi_nguyen.png',
    name: 'Quynh-Chi Nguyen',
    role: 'Staff Software Engineer',
    background: 'Professional life guru, unless you actually need life advice.',
    categories: ['engineering', 'product'],
  },
  {
    image: 'staffportrait_jeff_parsons.png',
    name: `Jeff Parsons`,
    role: 'Senior Tooling Engineer',
    background: '.',
    categories: ['engineering'],
  },
  {
    image: 'staffportrait_nick_spain.png',
    name: 'Nick Spain',
    role: 'Senior Platform Engineer',
    background: 'Prefers his graphs directed and acyclic.',
    categories: ['engineering'],
  },
  {
    image: 'staffportrait_ben_taylor.png',
    name: 'Ben Taylor',
    role: 'Software Engineer Team Lead',
    background: '',
    categories: ['engineering'],
  },
  {
    image: 'staffportrait_chamathi_gamage.png',
    name: 'Chamathi Gamage',
    role: 'Business Systems Engineer',
    background: '',
    categories: ['engineering'],
  },
  {
    image: 'staffportrait_jrdi_llosa.jpg',
    name: 'Jordi Llosa',
    role: 'Senior Product Engineer',
    background: '',
    categories: ['engineering'],
  },
  {
    image: 'staffportrait_lavinia_monteiro.png',
    name: 'Lavinia Monteiro',
    role: 'Software Engineer',
    background: '',
    categories: ['engineering'],
  },
  {
    image: 'staffportrait_shannon_cole.png',
    name: 'Shannon Cole',
    role: 'Senior Software Engineer',
    background: '',
    categories: ['engineering'],
  },
  {
    image: 'staffportrait_ally_macdonald.png',
    name: 'Ally Macdonald',
    role: 'API and Integrations Engineer',
    background: 'Only potatoes, no hello world. [Insert some appropriate English here]',
    categories: ['engineering'],
  },
  {
    image: 'staffportrait_chelsea_page-saxton2.png',
    name: 'Chelsea Page-Saxton',
    role: 'Product Administrative Assistant',
    background: '',
    categories: ['engineering'],
  },
  {
    image: 'staffportrait_stacey_carter.png',
    name: 'Stacey Carter',
    role: 'Senior Software Engineer',
    background: '',
    categories: ['engineering'],
  },
  {
    image: 'staffportrait_mackenzie_brown.png',
    name: 'Mackenzie Brown',
    role: 'IT Specialist',
    background: '',
    categories: ['engineering'],
  },
  {
    image: 'staffportrait_max_lewis.png',
    name: 'Max Lewis',
    role: 'Product Engineering Team Lead',
    background: '',
    categories: ['engineering'],
  },

  // Operations
  {
    image: 'staffportrait_emily_cheng.png',
    name: 'Emily Cheng',
    role: 'Finance Manager',
    background: 'My happiness is debit = credit.',
    categories: ['operations'],
  },
  {
    image: 'staffportrait_lilka_jones.png',
    name: 'Lilka Jones',
    role: 'Administrative Assistant',
    background:
      'Lily is a former elementary and preschool teacher. Her most valued memories with children have all taken place in nature or in the art studio. Lily is a creative problem solver and loves employing this skill in Team USA.',
    categories: ['operations', 'team-usa'],
  },
  {
    image: 'staffportrait_daniela_haddad.png',
    name: 'Daniela White',
    role: 'Employee Experience Coordinator',
    background: '',
    categories: ['operations'],
  },
  {
    image: 'staffportrait_gina_dibenedetto.jpg',
    name: 'Gina DiBenedetto',
    role: 'People & Culture Advisor',
    background: '',
    categories: ['operations'],
  },
  {
    image: 'staffportrait_anastasia_reid.jpg',
    name: 'Anastasia Reid',
    role: 'Teacher Happiness Team Member ',
    background: '',
    categories: ['operations'],
  },
  {
    image: 'staffportrait_makeila_reyes.png',
    name: 'Makeila Reyes',
    role: 'Head of People and Culture',
    background: '',
    categories: ['operations'],
  },
  {
    image: 'staffportrait_nicole_li.png',
    name: 'Nicole Li',
    role: 'Commercial Finance Partner',
    background: '',
    categories: ['operations'],
  },
  {
    image: 'staffportrait_isere_meaney.png',
    name: 'Isere Meaney',
    role: 'Office Administrator',
    background: '',
    categories: ['operations'],
  },

  // Product

  {
    image: 'staffportrait_gisela_beer.png',
    name: 'Gisela Beer',
    role: 'Illustrator',
    background: '',
    categories: ['product', 'content'],
  },
  {
    image: 'staffportrait_sean_hurley.png',
    name: 'Sean Hurley',
    role: 'Head of Design',
    background: 'Can cook eggs at least 9 different ways.',
    categories: ['product', 'content'],
  },
  {
    image: 'staffportrait_jack_brock.png',
    name: 'Jack Brock',
    role: 'Senior Product Designer',
    background: '',
    categories: ['product', 'content'],
  },
  {
    image: 'staffportrait_pooja_prabhakaran.png',
    name: 'Pooja Prabhakaran',
    role: 'Designer',
    background: 'Probably doodling if not making plans to cancel plans.',
    categories: ['product', 'content'],
  },
  {
    image: 'staffportrait_emma_ngo.png',
    name: 'Emma Ngo',
    role: 'Designer',
    background: 'Probably doodling if not making plans to cancel plans.',
    categories: ['product', 'content'],
  },
  {
    image: 'staffportrait_alex_russell.png',
    name: 'Alexandra Russell',
    role: 'Head of Curriculum and Pedagogy',
    background: 'Middle school teacher and fur baby mama.',
    categories: ['product'],
  },
  {
    image: 'staffportrait_mia_holton2.png',
    name: 'Mia Holton',
    role: 'Videographer',
    background: '',
    categories: ['product'],
  },
];
