import * as React from 'react';

import type { WrapPageElementBrowserArgs, WrapPageElementNodeArgs } from 'gatsby';

import {
  FACEBOOK_PIXEL_ID,
  GOOGLE_ADS_ID,
  GOOGLE_UA_ID,
  LINKEDIN_INSIGHT_PARTNER_ID,
} from '../constants';

import { GlobalStyles as GlobalStyle_2020 } from '../styles/2020/global';
import { GlobalStyles as GlobalStyle_2024 } from '../styles/2024';

import { FacebookPixel } from './FacebookPixel';
import { GoogleGtag } from './GoogleGtag';
import { LinkedInInsight } from './LinkedInInsight';

const newStylePaths = [
  '/2024/',
  '/what-is-stile/stile-pl/',
  '/states/oregon/',
  '/states/oregon-alignment/',
  '/states/north-carolina/',
  '/states/north-carolina-alignment/',
  `/who-we-are/our-principles/`,
  `/what-is-stile/whats-included/`,
  `/what-is-stile/technology-for-teaching/`,
  `/why-choose-stile/boost-reading-writing-and-math/`,
  `/why-choose-stile/literacy-and-numeracy/`,
];

// NOTE: It might seem to make sense to move <GlobalStyles /> to WrapRootElement instead
// but then gatsby-plugin-styled-components will not generate styles during build
// and you will get a flash of unstyled content on page load.
export function WrapPageElement({
  element,
  props,
}: WrapPageElementBrowserArgs | WrapPageElementNodeArgs) {
  return (
    <>
      {!(newStylePaths.indexOf(props.path) > -1) && <GlobalStyle_2020 />}
      {newStylePaths.indexOf(props.path) > -1 && <GlobalStyle_2024 />}
      {element}
      <GoogleGtag trackingIds={[GOOGLE_UA_ID, GOOGLE_ADS_ID]} />
      <LinkedInInsight partnerId={LINKEDIN_INSIGHT_PARTNER_ID} />
      <FacebookPixel pixelId={FACEBOOK_PIXEL_ID} />
    </>
  );
}
