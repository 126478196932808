import * as React from 'react';

import { useQuery } from 'react-query';

import { theme } from '../../../../styles/2020/theme';

import { TextBlock } from '../../blocks/TextBlock';
import { ButtonLink } from '../../common/ButtonLink';
import { Link } from '../../common/Link';
import { P, PageHeading, Prose, Section, Separator, Text } from '../../common/Primitives';
import { Center } from '../../layout/Center';
import { Column } from '../../layout/Column';
import { Row } from '../../layout/Row';

import { fetchStileJobById } from './utils';

export type JobDescriptionBlockProps = {
  jobId: string;
};

export function JobDescriptionBlock({ jobId }: JobDescriptionBlockProps) {
  const { isLoading, isError, data } = useQuery(
    ['job', jobId],
    async () => await fetchStileJobById(jobId),
    { enabled: !!jobId, refetchOnWindowFocus: false }
  );

  if (isLoading) {
    return (
      <TextBlock space={theme.space.l}>
        <PageHeading>Loading...</PageHeading>
        <ButtonLink url="/who-we-are/join-the-team/#OpenPositions">← Back to all jobs</ButtonLink>
      </TextBlock>
    );
  }

  if (!jobId) {
    return (
      <TextBlock space={theme.space.l}>
        <PageHeading>Apply for a role at Stile</PageHeading>
        <P>
          No job id specified. Perhaps you‘d like to browse our{' '}
          <Link url="/who-we-are/join-the-team/#OpenPositions">open positions</Link>.
        </P>
      </TextBlock>
    );
  }

  if (isError || !data) {
    return (
      <TextBlock space={theme.space.l}>
        <Column>
          <PageHeading>Whoops...</PageHeading>
          <P>
            Something went wrong trying to load the requested job. The url may be incorrect, the job
            may no longer exist, or your connection was reset.
          </P>
          <P>
            Please try again and if the issue persists{' '}
            <Link variant="inherit" url="/who-we-are/contact/">
              get in touch with us
            </Link>
            .
          </P>
        </Column>

        <ButtonLink url="/who-we-are/join-the-team/#OpenPositions">← Back to all jobs</ButtonLink>
      </TextBlock>
    );
  }

  const showFirstSeparator = data.city && data.country && (data.department || data.employmentType);
  const showSecondSeparator = data.department && data.employmentType;

  return (
    <Section>
      <Center max={theme.space.measure}>
        <Column space={theme.space.mToL}>
          <Column>
            <Column space={theme.space.xxxs}>
              <PageHeading>{data.title}</PageHeading>

              <Row justify="flex-start" space={theme.space.xxxs}>
                {data.city && data.country && (
                  <Text size="s">{`${data.city}, ${data.country}`.toUpperCase()}</Text>
                )}

                {showFirstSeparator && <Separator />}

                {data.department && <Text size="s">{data.department.toUpperCase()}</Text>}

                {showSecondSeparator && <Separator />}

                {data.employmentType && <Text size="s">{data.employmentType.toUpperCase()}</Text>}
              </Row>
            </Column>

            <Prose dangerouslySetInnerHTML={{ __html: data.fullDescription }} />
          </Column>

          <Row justify="space-between">
            <ButtonLink variant="secondary" url="/who-we-are/join-the-team/#OpenPositions">
              ← Back to all jobs
            </ButtonLink>

            <ButtonLink url={data.applyUrl.value} openInNewTab>
              Apply now
            </ButtonLink>
          </Row>
        </Column>
      </Center>
    </Section>
  );
}
