import * as React from 'react';

import { useQueryClient } from 'react-query';

import { JobRole } from '../../../../types/data';

import { theme } from '../../../../styles/2020/theme';

import { Link } from '../../common/Link';
import { Divider, Separator, Text } from '../../common/Primitives';
import { Column } from '../../layout/Column';
import { Row } from '../../layout/Row';

import { fetchStileJobById } from '../JobDescriptionBlock/utils';

type JobDetailProps = JobRole & { knownJobIds?: Array<string> };

export function JobDetail({
  uuid,
  title,
  city,
  department,
  employmentType,
  knownJobIds,
}: JobDetailProps) {
  const queryClient = useQueryClient();

  const prefetchJob = async () => {
    await queryClient.prefetchQuery(
      ['job', uuid.value],
      async () => await fetchStileJobById(uuid.value)
    );
  };

  const jobPageSlug =
    knownJobIds && knownJobIds.includes(uuid.value)
      ? `/who-we-are/apply/${uuid.value}/`
      : `/who-we-are/apply/?job=${uuid.value}`;

  const showFirstSeparator = city && (department || employmentType);
  const showSecondSeparator = department && employmentType;

  return (
    <Column space={theme.space.xxs}>
      <Link url={jobPageSlug} onMouseEnter={prefetchJob}>
        {title}
      </Link>

      <Row justify="flex-start" space={theme.space.xxxs}>
        {city && <Text size="s">{city.toUpperCase()}</Text>}

        {showFirstSeparator && <Separator />}

        {department && <Text size="s">{department.toUpperCase()}</Text>}

        {showSecondSeparator && <Separator />}

        {employmentType && <Text size="s">{employmentType.toUpperCase()}</Text>}
      </Row>

      <Divider color="grey3" />
    </Column>
  );
}
